<template>
  <div class="d-flex flex-column">
    <v-dialog
      v-if="newBrand"
      scrollable
      v-model="showEditBrand"
      max-width="80%"
    >
      <v-form ref="newBrandform" v-model="validNewBrand">
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">Nueva Marca</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="newBrand.id"
                  v-bind:label="$t('brands.brand')"
                  v-bind:placeholder="$t('start_typing_to_search')"
                  item-text="name"
                  item-value="id"
                  :items="availableBrands"
                  filled
                  required
                  :rules="requiredRules"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="newBrand.bpoSales"
                  v-bind:label="`Pedido`"
                  v-bind:placeholder="$t('start_typing_to_search')"
                  item-text="label"
                  item-value="id"
                  :items="orderOpts"
                  filled
                  required
                  :rules="requiredRules"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="() => (showEditBrand = false)"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onNewBrandSaveClick(newBrand)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-if="newOperator"
      scrollable
      v-model="showEditOperator"
      max-width="80%"
    >
      <v-form ref="newOperatorform" v-model="validNewOperator">
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">Nuevo Operador</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  v-model="newOperator.id"
                  label="Operador"
                  v-bind:placeholder="$t('start_typing_to_search')"
                  item-text="name"
                  item-value="id"
                  :items="availableOperators"
                  filled
                  required
                  :rules="requiredRules"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="newOperator.code"
                  label="Código Operador"
                  prepend-icon="mdi-key-variant"
                  placeholder="Código Operador"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="() => (showEditOperator = false)"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onNewOperatorSaveClick(newOperator)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-1">
        <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
          <v-card elevation="5" outlined v-if="posData">
            <v-card-title>Datos POS</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.name"
                    v-bind:label="$t('brands.name')"
                    required
                    :rules="requiredRules"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.storeM2"
                    type="number"
                    v-bind:label="$t('Store m2')"
                    :rules="storem2Rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.chainId"
                    v-bind:label="$t('chain')"
                    :items="chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    @change="onChangeChainId(posData.chainId, 1)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.channelId"
                    v-bind:label="$t('channel')"
                    :items="channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    readonly
                    @change="onChangeChannelId(posData.channelId, 1)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.subChainId"
                    v-bind:label="`Sub ${$t('chain')}`"
                    :items="sub_chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                    @change="onChangeChainId(posData.subChainId, 2)"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.subChannelId"
                    v-bind:label="`Sub ${$t('channel')}`"
                    :items="sub_channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                    readonly
                    @change="onChangeChannelId(posData.subChannelId, 2)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.sub2ChainId"
                    v-bind:label="`Sub-Sub ${$t('chain')}`"
                    :items="sub2_chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="posData.sub2ChannelId"
                    v-bind:label="`Sub-Sub ${$t('channel')}`"
                    :items="sub2_channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    clearable
                    readonly
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.town"
                    v-bind:label="$t('town')"
                    outlined
                    class="editpos_input"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.postalCode"
                    label="CP"
                    outlined
                    class="editpos_input"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.address"
                    v-bind:label="$t('address')"
                    outlined
                    class="editpos_input"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.addressObservation"
                    v-bind:label="$t('address observation')"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <contactPersonTable
                    :posData="posData"
                    :updatePosContacts="updatePosContacts"
                    :apiGetBrandsUrl="
                      `worksession/getbasedata?isFlag=searchbrand`
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-title>Datos Pedidos</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>

            <v-card class="m-3" elevation="2" outlined>
              <v-card-title>Marcas</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="brandTableHeaders"
                  :items="brandsUsed"
                  class="elevation-1"
                  :hide-default-footer="true"
                >
                  <template v-slot:item.actions="{ item }">
                    <b-button
                      size="sm"
                      variant="outline-light"
                      @click="removeBrand(item)"
                      v-if="item.userEdit"
                    >
                      <b-icon icon="trash" variant="danger"></b-icon>
                    </b-button>
                  </template>

                  <template v-slot:item.salesType="{ item }">
                    {{
                      item.bpoSales === "NO_SALE"
                        ? "Sin Venta"
                        : item.bpoSales === "SUGGESTED_ORDER"
                        ? "Venta sugerida"
                        : "Venta firme"
                    }}
                  </template>
                </v-data-table>

                <b-button
                  @click="addNewBrand"
                  style="color: white;"
                  variant="primary"
                  size="sm"
                  class="mt-4"
                >
                  <b-icon icon="plus-circle"></b-icon>
                  Añadir
                </b-button>
              </v-card-text>
            </v-card>

            <v-card class="m-3" elevation="2" outlined>
              <v-card-title>Operadores</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="operatorTableHeaders"
                  :items="operatorsUsed"
                  class="elevation-1"
                  :hide-default-footer="true"
                >
                  <template v-slot:item.actions="{ item }">
                    <b-button
                      size="sm"
                      variant="outline-light"
                      @click="removeOperator(item)"
                      v-if="item.userEdit"
                    >
                      <b-icon icon="trash" variant="danger"></b-icon>
                    </b-button>
                  </template>
                </v-data-table>

                <div class="alert-danger">{{ operatorsMissingError }}</div>

                <b-button
                  @click="addNewOperator"
                  style="color: white;"
                  variant="primary"
                  size="sm"
                  class="mt-4"
                >
                  <b-icon icon="plus-circle"></b-icon>
                  Añadir
                </b-button>
              </v-card-text>
            </v-card>

            <v-card-title>Datos Fiscales</v-card-title>
            <v-divider class="mx-4 my-0"></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalName"
                    v-bind:label="`Fiscal ${$t('brands.name')}`"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.vatNumber"
                    v-bind:label="`CIF`"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalTown"
                    v-bind:label="`Fiscal ${$t('town')}`"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalPostalCode"
                    v-bind:label="`Fiscal CP`"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="posData.fiscalAddress"
                    v-bind:label="`Fiscal ${$t('address')}`"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-content-center">
              <v-btn
                color="success"
                dark
                class="mx-1 my-2"
                :loading="isSaving"
                @click="onSaveClick(posData)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <b-alert v-if="!posData" show variant="info">
            <b-icon
              icon="arrow-counterclockwise"
              animation="spin-reverse"
            ></b-icon>
            cargando datos...
          </b-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import { logInfo, logError, logWarning } from "@/utils";
import contactPersonTable from "@/components/contactPersonTable";
import _ from "lodash";

export default {
  name: "worksessionEditPos",
  props: ["id", "worksessionPosId"],
  components: {
    contactPersonTable
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", ["currentPosData"]),

    allBrands() {
      return _.get(this.posData, "routePosBrands", []).map(el => el.brand);
    },

    prevSetBrands() {
      return _.get(this.posData, "brand_perfectstore_objectives", []).map(
        el => {
          return {
            bpoSales: el.bpoSales,
            ...el.brand
          };
        }
      );
    },

    availableBrands() {
      //all brands for this route-pos that remain available
      const idsUsed = this.brandsUsed.map(el => el.id);
      return this.allBrands.filter(el => !idsUsed.includes(el.id));
    },

    allOperators() {
      return _.get(this.posData, "operatorOpts", []);
    },

    prevSetOperators() {
      return _.get(this.posData, "pos_codes", []).map(el => {
        return {
          code: el.code,
          ...el.operator
        };
      });
    },

    availableOperators() {
      const idsUsed = this.operatorsUsed.map(el => el.id);
      return this.allOperators.filter(el => !idsUsed.includes(el.id));
    }
  },

  data: function() {
    return {
      brandTableHeaders: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "25px"
        },

        {
          text: "Marca",
          value: "name",
          sortable: false
        },
        {
          text: "Tipo Venta",
          value: "salesType",
          sortable: false
        }
      ],

      operatorTableHeaders: [
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "25px"
        },

        {
          text: "Operador",
          value: "name",
          sortable: false
        },
        {
          text: "Codigo",
          value: "code",
          sortable: false
        }
      ],

      orderOpts: [
        { id: "NO_SALE", label: "Sin Venta" },
        { id: "SUGGESTED_ORDER", label: "Venta sugerida" },
        { id: "FIRM_ORDER", label: "Venta firme" }
      ],

      //brands Edition
      showEditBrand: false,
      brandsUsed: [],
      newBrand: null,
      validNewBrand: false,

      operatorsMissingError: null,

      //Operators Edition
      showEditOperator: false,
      operatorsUsed: [],
      newOperator: null,
      validNewOperator: false,

      posData: null,
      chain_list: [],
      channel_list: [],
      sub_chain_list: [],
      sub_channel_list: [],
      sub2_chain_list: [],
      sub2_channel_list: [],
      valid: true,
      requiredRules: [v => !!v || "Required"],
      storem2Rules: [
        v =>
          !v ||
          (v >= 0 && v <= 2000) ||
          "Incorrect value ! this value should be between 0 and 2000."
      ],
      emailRules: [
        // (v) => !!v || "Required",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      isSaving: false
    };
  },
  methods: {
    async onChangeChainId(parentId, subDepth) {
      let fetch_url = `worksession/getbasedata?isFlag=chainlist`;
      let chainList = [];
      if (parentId) {
        fetch_url += "&parentId=" + parentId;
        let { chain_list } = await ApiService.post(fetch_url);
        chainList = chain_list;
      }
      if (subDepth === 1) {
        this.sub_chain_list = chainList;
        this.sub2_chain_list = [];
      } else if (subDepth === 2) {
        this.sub2_chain_list = chainList;
      }
    },
    async onChangeChannelId(parentId, subDepth) {
      let fetch_url = `worksession/getbasedata?isFlag=channel_list`;
      let channelList = [];
      if (parentId) {
        fetch_url += "&parentId=" + parentId;
        let { channel_list } = await ApiService.post(fetch_url);
        channelList = channel_list;
      }
      if (subDepth === 1) {
        this.sub_channel_list = channelList;
        this.sub2_channel_list = [];
      } else if (subDepth === 2) {
        this.sub2_channel_list = channelList;
      }
    },

    onNewBrandSaveClick(brand) {
      if (!this.validNewBrand) {
        return logWarning("Rellena los campos oblogatorios");
      }
      const found = this.allBrands.find(el => el.id === brand.id);
      found.userEdit = true;
      found.bpoSales = brand.bpoSales;
      this.posData.extended.brands.push(found);
      this.brandsUsed.push(found);
      this.showEditBrand = false;
    },

    removeBrand(brand) {
      this.posData.extended.brands = this.posData.extended.brands.filter(
        el => el.id !== brand.id
      );
      this.brandsUsed = this.brandsUsed.filter(el => el.id !== brand.id);
    },

    addNewBrand() {
      this.newBrand = {};
      this.showEditBrand = true;
    },

    addNewOperator() {
      this.newOperator = {};
      this.showEditOperator = true;
    },

    removeOperator(operator) {
      this.posData.extended.operators = this.posData.extended.operators.filter(
        el => el.id !== operator.id
      );
      this.operatorsUsed = this.operatorsUsed.filter(
        el => el.id !== operator.id
      );
    },

    onNewOperatorSaveClick(operator) {
      if (!this.validNewOperator) {
        return logWarning("Rellena los campos oblogatorios");
      }
      const found = this.allOperators.find(el => el.id === operator.id);
      found.userEdit = true;
      found.code = operator.code;
      this.posData.extended.operators.push(found);
      this.operatorsUsed.push(found);
      this.showEditOperator = false;
    },

    async onSaveClick(posData) {
      if (this.$refs.form.validate()) {
        try {
          let body = posData;

          //checking if a new firm_order is set. If so, this pos needs to also have an operator

          const hasFirmOrder = _.get(body, "extended.brands", []).reduce(
            (prev, el) => {
              prev |= el.bpoSales === "FIRM_ORDER";
              return prev;
            },
            false
          );

          const hasOperators = !!_.get(body, "extended.operators", []).length;

          this.operatorsMissingError =
            hasFirmOrder && !hasOperators
              ? "Añade un operador antes de continuar"
              : null;

          if (this.operatorsMissingError) {
            logError(this.operatorsMissingError);
            return;
          }

          this.isSaving = true;
          if (body.id) {
            await ApiService.put(`worksession/savepos/${body.id}`, body);
          }
          this.isSaving = false;
          logInfo("POS actualizada");
          this.init();
        } catch (error) {
          this.isSaving = false;
        }
      }
    },

    updatePosContacts(newPosContacts) {
      this.posData.pos_contacts = newPosContacts;
      this.init();
    },
    async init() {
      await this.$store.dispatch("myroute/getCurrentPosData", {
        sessionId: this.id,
        worksessionPosId: this.worksessionPosId
      });
      this.posData = this.currentPosData;
      let { chain_list } = await ApiService.post(
        `worksession/getbasedata?isFlag=chainlist`
      );
      this.chain_list = chain_list;
      let { channel_list } = await ApiService.post(
        `worksession/getbasedata?isFlag=channel_list`
      );
      this.channel_list = channel_list;
      if (this.posData) {
        //extending posData
        this.posData.extended = {
          brands: [],
          operators: []
        };

        this.brandsUsed = this.prevSetBrands;
        this.operatorsUsed = this.prevSetOperators;

        if (this.posData?.subChainId || this.posData?.chainId) {
          await this.onChangeChainId(this.posData.chainId, 1);
        }
        if (this.posData?.sub2ChainId || this.posData?.subChainId) {
          await this.onChangeChainId(this.posData.subChainId, 2);
        }
        if (this.posData?.subChannelId || this.posData?.channelId) {
          await this.onChangeChannelId(this.posData.channelId, 1);
        }
        if (this.posData?.sub2ChannelId || this.posData?.subChannelId) {
          await this.onChangeChannelId(this.posData.subChannelId, 2);
        }
      }
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style></style>
